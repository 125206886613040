<template>
  <div>
    <div class="wrapperDiv">
      <h1>
        <span>
          {{ $t("Thank Y") }}<i class="far fa-smile-beam"></i
          >{{ $t("u") }}</span
        >
      </h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "FinishExam"
};
</script>

<style lang="css" scoped>
.wrapperDiv {
  text-align: center;
  margin-top: 200px;
}
h1 {
  font-size: 150px;
  color: #7295fb;
}
h1 .far {
  font-size: 110px;
  margin: 0px 8px;
}
h1 span {
  border-bottom: 1px solid;
}
h1,
h3 {
  margin-bottom: 0;
  font-family: sans-serif;
}
h3 {
  color: #676767;
  font-size: 44px;
}
img {
  color: #7295fb;
}
</style>
